define(['backbone'], function(Backbone) {
	var widgets, View;

	View = Backbone.View.extend({
		constructor(options) {
			this.options = options || {};
			View.__super__.constructor.call(this, options);
		},

		render(args) {
			var self = this, context = args || {}, tpl;

			// Does the child have a prerender method?
			if (self.prerender && _.isFunction(self.prerender)) {
				self.prerender(context);
			}

			if (this.template) {
				// Continue normal render
				tpl = _.template($('#tpl-' + this.template).html());
				self.$el.html(tpl(context));
			}

			// Does the child have a postrender method?
			if (self.postrender && _.isFunction(self.postrender)) {
				self.postrender(context);
			}
		},

		destroy() {
			var self = this;
			this.trigger('destroyed');
			this.off();
			this.stopListening();
			if (this.$el) {
				this.$el.empty();
				this.undelegateEvents();
				this.$el.off();
			}
			this.$el = null;
			this.el = null;
			_.each(this, function(obj) {
				if (obj && _.isFunction(obj.off)) {
					obj.off(undefined, undefined, self);
				}
			});
			_.each(this.options, function(obj) {
				if (obj && _.isFunction(obj.off)) {
					obj.off(undefined, undefined, self);
				}
			});
		},

		/**
		Responsible for initializing all of the child views and for starting the select2 plugin
		@meth widgets
		@param {Jquery.Selector} [root] Root element to drill down from. If undefined, method uses this.$el
		@return {undefined}
		**/
		widgets(root) {
			var self = this,
				$root, obj, conf, role,
				nodelist, i, len, elem;

			self.widgets_list = self.widgets_list || [];

			if (!root || !_.isString(root)) {
				$root = self.$el;
			} else {
				$root = self.$(root);
			}
			if ($root && $root.length > 0) {
				$root.find('select.select2').each(function(idx, el) {
					var $this = $(el);
					if ($this.data('select2')){
						return;
					}
					if ($this.attr('select2') == 'false'){
						return;
					}
					obj = $this.data();
					conf = {
						width: "auto",
						dropdownAutoWidth: "true"
						// selectOnBlur: true
					};
					if (!$this.prop("multiple")) {
						conf.selectOnBlur = true;
					}
					if ($this.attr("searchable") == "false") {
						_.extend(conf, {
							minimumResultsForSearch: "-1"
						});
					}
					if ($this.attr("multiple")) {
						_.extend(conf, {
							containerCss: {
								"min-width": "150px"
							}
						});
					}
					if (obj) {
						_.extend(conf, obj);
					}
					$this.select2(conf);
					$this.data('select2').search.find(':input').attr('title', $this.attr('title'));
					$this.data('select2').selection.find(':input').attr('title', $this.attr('title'));
					$this.data('select2').container.find(':input').attr('title', $this.attr('title'));
					$this.data('select2').dropdown.find(':input').attr('title', $this.attr('title'));
				});

				nodelist = $root[0].querySelectorAll(".widget, [data-role]");
				//$root.find(".widget, [data-role]").each(function() {
				for (i = 0, len = nodelist.length; i < len; i++) {
					elem = nodelist[i];

					// is there a widget constructed here already?
					//obj = $(elem).data("widget");
					//if (!obj) {
					// get the role/widget type so we can figure out how to create it
					role = elem.getAttribute("data-role") || elem.getAttribute("role");
					if (widgets[role]) {
						// create the widget
						obj = new widgets[role]({
							el: elem
						});
						obj.__parent__ = self;

						// call its render function, if it has one
						if (obj.render && _.isFunction(obj.render)) {
							obj.render();
						}

						// attach the widget object to the dom element
						$(elem).data("widget", obj);

						// remove widget / data-role
						elem.classList.remove("widget");
						elem.removeAttribute("data-role");
						elem.setAttribute("data-role-initialized", role);
					}
					//}
					// add the widget to the view's internal list of widgets
					// this allows them to be cleaned up in the destructor
					self.widgets_list.push(obj);
				}
			}
		},

		/**
		get the view controlling a widget by it's selector
		@method getWidget
		@return {Array} widgets
		**/
		getWidgets() {
			return this.widgets_list;
		},

		// get a widget object instance
		getWidget(selector) {
			return this.$(selector).data("widget");
		},

		// manually add a widget
		addWidget(obj) {
			if (obj) {
				this.widgets_list.push(obj);
			}
			return obj;
		},

		// destroy all widgets in the view
		removeWidgets() {
			var i,
				widgets_list = this.widgets_list,
				widget;

			// destroy widgets within this view
			for (i = 0; i < widgets_list.length; i++) {
				widget = widgets_list[i];
				if (widget && widget.$el){
					widget.$el.removeData("widget");
					if (widget && widget.destroy && _.isFunction(widget.destroy) && widget.destroyed == false) {
						widget.destroy();
					}
				}
				//widgets_list[i] = null;
			}
			this.widgets_list = [];
		}
	}, {
		setWidgets(w) {
			widgets = w;
		}
	});

	return View;
});
