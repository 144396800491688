
// Make sure jquery is loaded
import jquery from 'jquery';

import app from './app.js';
import Backbone from 'backbone';
import Router from './router.js';
import AlertView from 'views/AlertView.js';
// import * as lib from 'lib';
fetch('./env.json')
	.then(res => {
		if (!res.ok) {
			throw new Error("HTTP error " + res.status);
		}
		return res.json();
	})
	.then(env => {
		// BAREBONES_API defined in .env and webpack.common.js
		window.g = {
			barebones: env.__BAREBONES_API__ || "https://app.azaleahealth.com/barebones/",
			soketi: env.__SOKETI_HOST__ || "soketi.azaleahealth.com"
		};
		app.dev = env.environment == 'development';
		app.AlertView = new AlertView();
		app.Router = new Router({env});
		Backbone.history.start();
	});
