define(['app', 'views/HandoutView'], function(app, HandoutView) {
	var WaitView = app.View.extend({
		el: 'main',
		template: 'waiting',

		initialize() {
			this.render();
		},

		postrender() {
			// Configure message channels
			this.initPusher();
		},

		initPusher() {
			var self = this;
			app.pusher(function(pusher) {
				var name = 'user-' + app.SessionModel.get('usr_id') + '-sign-handouts-device' + (app.dev ? '-dev' : ''),
					channel = pusher.subscribe(name);

				// checking if there's someone else logged in...
				self.fireEvent('device-whats-up', {}, function() {
					channel.bind('device-nothing-much', function() {
						// log out
						pusher.unsubscribe(name);
						app.SessionModel.clear();
						app.error('There is another device that is already logged into this EHR account. Please close the signer on that device first.');
						setTimeout(self.onTimeout, 4000);
					}, self);
				});

				// listening for handouts...
				channel.bind('sent-handout', self.onHandoutReceived, self);
				channel.bind('device-whats-up', self.onWhatsUp, self);
				channel.bind('ping', self.pong, self);
			});
		},

		onWhatsUp(data) {
			this.fireEvent('device-nothing-much', data, function() {
				// responded to whats up
			});
		},

		onTimeout() {
			window.location.reload();
		},

		pong() {
			this.fireEvent('pong', {phs_id: this.phs_id}, function() {
				// responded to ping
			});
		},

		onHandoutReceived(data) {
			var self = this;
			// handout received from pusher

			if (this.handout) {
				// device is in use. rejecting...
				this.fireEvent('device-in-use', data);
				return;
			}

			self.phs_id = data.phs_id;

			self.$el.find('#waiting').hide();
			this.fireEvent('device-confirm-received', data, function(response) {
				var handoutView = new HandoutView();

				// confirmation sent
				self.handout = response.handout;

				// render handout view
				handoutView.render({
					handout: response.handout
				});

				self.listenToOnce(handoutView, 'cancelled', function() {
					self.fireEvent('device-cancel-handout', data, function() {
						// cancel sent
						self.resetView();
					});
				});

				self.listenToOnce(handoutView, 'send', function(hand) {
					data.phs_content = hand.phs_content;
					if (data.phs_content){
						self.fireEvent('device-send-handout', data, function() {
							// sent handout to ehr
							app.success("Handout was successfully submitted.");
							handoutView.destroy();
							self.resetView();
						});
					}

				});
			});
		},

		resetView() {
			this.$el.find('#waiting').show();
			this.handout = null;
		},

		fireEvent(event, data, success, error) {
			$.ajax({
				type: "POST",
				url: "api/fire.php",
				dataType: "json",
				data: {
					socket_id: app.config.pusher.socket_id,
					event,
					data,
					ahis: app.SessionModel.get('ahis')
				},
				success(response, ts, jqXHR) {
					if (response.success == 1 && _.isFunction(success)) {
						success(response);
					} else {
						this.error(jqXHR, ts, response.error);
					}
				},
				error(response) {
					app.error(response.responseText);
					if (_.isFunction(error)){
						error(response);
					}
				}
			});
		}
	});
	return WaitView;
});
