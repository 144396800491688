/* global define */
define(['app'], function(app) {
	var SessionModel = app.Model.extend({
		type: 'barebones',
		url: 'V1/session/create',

		sync(method, model, options) {
			if (method === "read") {
				options.type = "post";
				options.data = options.data || {};
			}

			return SessionModel.__super__.sync.apply(this, arguments);
		},

		parse(response) {
			if (response.user){
				response.user.ahis = response.ahis;
				return response.user;
			}
			return undefined;
		}
	});

	return SessionModel;
});
