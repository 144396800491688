// Copyright 2019 Azalea Health Innovations, Inc.

define(['app'], function(app) {
	var matchPatterns = {
			// [[??]]
			questionString: "[[]{2,2}[?]{2,2}]]",
			// [[##]]
			numberString: "[[]{2,2}[#]{2,2}]]",
			// [[date]]
			dateString: "[[]{2,2}date]]",
			// This grabs [[
			// Then any number of characters in [a-zA-Z0-9 <>!=&;,'?().\\\/\+-:], but at least one
			// Then it repeatedly matches the following
			//   A | followed by any number of characters in [a-zA-Z0-9 <>!=&;,'?().\\-:], but at least one
			// Then it finishes by grabbing ]]
			// The regex sums up the following rules:
			//   1. Only characters from [a-zA-Z0-9 <>!=&;,'?().\\\/\+-:] or | can appear in the variable
			//   2. There must be at least one |, and it must separate at least two valid character groups
			//   3. No empty character groups are allowed
			multiselectString: "[[]{2,2}[a-zA-Z0-9 <>!=&;,'?().\\/+-:]+(?:[|][a-zA-Z0-9 <>!=&;,'?().\\/+-:]+)+]]",
			// This regex matches <anything> (except <br ... >)
			// newline is included because html can be split across lines, (the 'm' flag should probably be specified
			// for this regex).
			// NOTE: This is not a foolproof method for removing html. Regular expressions cannot be used to parse html.
			htmlStripString: "<(?!br)(?:.|\n)*?>"
		},

		/**
	 * @extends app.View
	 * @param {String} [options.concept] The "area" this view will replace values in. Defaults to ENCOUNTER SOAP FIELD
	 * @param {String} [options.text] The non-html text to be used for replacing values
	 * @param {Function} [options.callback] A callback on completion. Argument is modified version of options.text
	 */
		VariableReplacementView = app.View.extend({

			className: "system-VariableReplacementView",

			title(){
				return "Variable Replacement";
			},

			events: {
				"click #btn_save": "onSave",
				"click #btn_cancel": "onCancel"
			},

			matchPatterns,

			matchPatternString:
			matchPatterns.dateString + "|" +
			matchPatterns.multiselectString + "|" +
			matchPatterns.numberString + "|" +
			matchPatterns.questionString,

			initialize(){
				var self = this;
				self.options.concept = self.options.concept || "ENCOUNTER SOAP FIELD";
				if (self.options.concept === "esp_objective") {
					self.options.concept = "OBJECTIVE NOTES";
				}

				// Setup regexp's for later use.
				self.dateRegex = new RegExp(self.matchPatterns.dateString);
				self.matchPatternRegex = new RegExp(self.matchPatternString, "gm");
				self.multiselectRegex = new RegExp(self.matchPatterns.multiselectString);
				self.numberRegex = new RegExp(self.matchPatterns.numberString);
				self.questionRegex = new RegExp(self.matchPatterns.questionString);
				self.stripRegex = new RegExp(self.matchPatterns.htmlStripString, 'gm');

				// Split the contents of the original text into strippable and
				// non-strippable segments.
				self.split_text = self.options.text.split(self.stripRegex);
			},

			render() {
				var self = this;

				// self.tpl = awa.tpl.get("variable_replacement");
				// self.tplArgs = { area: self.options.concept };
				// VariableReplacementView.__super__.render.call(this);
				// self.$("#note_contents").html(self.transformText({
				// 	add: true
				// }));

				self.widgets();
			},

			onSave() {
				var self = this;

				if (_.isFunction(self.options.callback)){
					self.options.callback(self.transformText({
						remove: true
					}));
				}
				self.close();
			},

			onCancel() {
				var self = this;
				self.close();
			},

			transformText(options){
				var self = this,
					text = "";

				// For each non strippable segment in the original text
				_.each(self.split_text, function(element, index, list) {

					if (options.add){
					// Add html inputs for each replacement in the segment
						text += self.addReplacementInputElements(element, index);

					} else if (options.remove){
					// Find replacement input elements added to the segment in the
					// modified version of the text.
					// If an input value was set, insert that value (instead of the
					// original replacement) into the segment.
					// Or if no replacement value was set, remove the input element
					// and restore the original replacement.
						text += self.removeReplacementElements(element, index);

					} else if (options.remove_inline){
					// This will replace replacements inline in self.$el.html().
					// This is not the recommended way to use this, as it does
					// not guarantee that replacements are made correctly.
						self.removeReplacementElementsInline(element, index);
					} else {
						text += element;
					}

					// Add the following strippable segment onto the output
					if (index < list.length - 1){
						text += self.stripRegex.exec(self.options.text)[0];
					}
				});
				// Reset the regex for later searching
				self.stripRegex.lastIndex = 0;

				if (options.remove_inline){
					return self.$el.html();
				}
				return text;
			},

			addReplacementInputElements(item, location){
				var self = this,
					split_item,
					changed_item = "";

				// If this item contains a replacement
				if (self.matchPatternRegex.test(item)){
				// Reset the regex for later searching
					self.matchPatternRegex.lastIndex = 0;
					// Split the item into replacement and non-replacement segments
					split_item = item.split(self.matchPatternRegex);
					// For each non-replacement segment
					_.each(split_item, function(element, index, list) {
						var replacement,
							options;
						// Add this segment to the output
						changed_item += element;
						// If we can work on the following replacement segment, do
						// that here too
						if (index < list.length - 1){
						// Grab the next replacement segment
							replacement = self.matchPatternRegex.exec(item)[0];
							// If it's a double question mark
							if (self.questionRegex.test(replacement)){
								changed_item += '<input type="text" ' +
								'placeholder="[[??]]" ' +
								// Here we are marking the replacement input
								// element, so that we know where to insert any
								// input values later on.
								'class="replacement l' + location + ' i' + index + '" ' +
								'size="20" />';
								// If it's a double number
							} else if (self.numberRegex.test(replacement)){
								changed_item += '<input type="text" ' +
								'placeholder="[[##]]" ' +
								'class="replacement l' + location + ' i' + index + '" ' +
								'size="10" />';
								// If it's a date
							} else if (self.dateRegex.test(replacement)){
								changed_item += '<input type="text" ' +
								'class="widget dates replacement l' + location + ' i' + index + '" ' +
								'role="dateselector" />';
								// If it's a multiselect
							} else if (self.multiselectRegex.test(replacement)){
							// Remove the [[ and ]] from the replacement
								replacement = replacement.replace(/\[|\]/g, '');
								options = replacement.split('|');

								//Assemble a multiselect
								changed_item += '<select ' +
								'class="multiselects replacement l' + location + ' i' + index + '" ' +
								'data-role="select.multiselect" ' +
								'data-selected="Select options...">';
								changed_item += '<option data-hidden="true" value="">Select options...</option>';
								_.each(options, function(option) {
									changed_item += '<option value="' + option + '">' + option + '</option>';
								});
								changed_item += '</select>';
							}
						}
					});
					// If this item doesn't contain a replacement, just pass it back.
				} else {
					changed_item = item;
				}

				// Reset the regex for later searching
				self.matchPatternRegex.lastIndex = 0;
				return changed_item;
			},

			removeReplacementElements(item, location){
				var self = this,
					split_item,
					changed_item = "",
					$replacements = self.$('.replacement');

				// If this item contains a replacement
				if (self.matchPatternRegex.test(item)){
				// Reset the regex for later searching
					self.matchPatternRegex.lastIndex = 0;
					// Split the item into replacement and non-replacement segments
					split_item = item.split(self.matchPatternRegex);
					// For each non-replacement segment
					_.each(split_item, function(element, index, list) {
						var replacement,
							replaced_element;

						// Add this segment to the output
						changed_item += element;
						// If we can work on the following replacement segment, do
						// that here too
						if (index < list.length - 1){
						// Grab the next replacement segment
							replacement = self.matchPatternRegex.exec(item)[0];
							// Grab the corresponding replacement input element from
							// the html
							replaced_element = $replacements.filter('.l' + location + '.i' + index)[0];
							// If an input value was set, insert that value (instead
							// of the original replacement) into the output.
							// Or if no replacement value was set, remove the input
							// element and restore the original replacement.
							changed_item += self.sanitize(replaced_element) || replacement;
						}
					});
				} else {
					changed_item = item;
				}

				// Reset the regex for later searching
				self.matchPatternRegex.lastIndex = 0;
				return changed_item;
			},

			removeReplacementElementsInline(item, location){
				var self = this,
					split_item,
					$replacements = self.$('.replacement');

				// If this item contains a replacement
				if (self.matchPatternRegex.test(item)){
				// Reset the regex for later searching
					self.matchPatternRegex.lastIndex = 0;
					// Split the item into replacement and non-replacement segments
					split_item = item.split(self.matchPatternRegex);
					// For each non-replacement segment
					_.each(split_item, function(element, index, list) {
						var replacement,
							replaced_element,
							input_parent;
						// If we can work on the following replacement segment, do
						// that here too
						if (index < list.length - 1){
						// Grab the next replacement segment
							replacement = self.matchPatternRegex.exec(item)[0];
							// Grab the corresponding replacement input element from
							// the html
							replaced_element = $replacements.filter('.l' + location + '.i' + index)[0];
							if (replaced_element){
								if (replaced_element.getAttribute("role") == "dateselector" ||
								replaced_element.parentElement.classList.contains("awa-ui-searchable-select-wrapper")
								){
									input_parent = replaced_element.parentElement;
								} else {
									input_parent = replaced_element;
								}
								// If an input value was set, insert that value (instead
								// of the original replacement) into the output.
								// Or if no replacement value was set, remove the input
								// element and restore the original replacement.
								input_parent.outerHTML = self.sanitize(replaced_element) || replacement;
							}
						}
					});
				}

				// Reset the regex for later searching
				self.matchPatternRegex.lastIndex = 0;
			},

			sanitize(element){
				var value = $(element).val();

				if (_.isArray(value)){
					value = _.difference(value, [""]);
					value = value.join(", ");
				}
				value = _.escape(value);
				return value;
			}

		}, {
			matchPatternString:
			matchPatterns.dateString + "|" +
			matchPatterns.multiselectString + "|" +
			matchPatterns.numberString + "|" +
			matchPatterns.questionString
		});

	return VariableReplacementView;
});
