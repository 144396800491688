define(['lib/View', 'lib/Model', 'lib/ViewManager', 'lib/widgets', 'pusher', 'jquery', 'simplify-js'],
(View, Model, ViewManager, widgets, Pusher, jQuery, simplify) => {
	const app = {
		View,
		Model,
		ViewManager,
		SessionModel: null,
		AlertView: null,
		pusherInstance: null,
		widgets,

		config: {
			barebones: "https://azaleahealth.com/",
			pusher: {
				app_id: "38a11d3fff90cf2c27d3",
				socket_id: null
			}
		},

		error(text) {
			this.AlertView.trigger('error', text);
		},

		success(text) {
			this.AlertView.trigger('success', text);
		},

		pusher(success) {
			var self = this;

			if (!self.pusherInstance) {
				self.pusherInstance = new Pusher(this.config.pusher.app_id, {
					wsHost: window.g.soketi,
					wsPort: 443,
					forceTLS: true,
					encrypted: true,
					enabledTransports: ['ws', 'wss']
				});
			}

			self.pusherInstance.connection.bind('connected', function() {
				// pusher connected
				self.config.pusher.socket_id = self.pusherInstance.connection.socket_id;

				success(self.pusherInstance);
			});
		}
	};

	// Set globals needed for jSignature vendor packages
	// I'm not sure why the webpack ProvidePlugin shim is not setting these
	// automatically when jSignature is loaded - Nate N, 10/18/2019
	window.jQuery = jQuery;
	window.$ = jQuery;
	window.simplify = simplify;

	// Assets path
	window.IMAGES = "build/assets/images/";

	return app;
});
