define(['moment'], function(moment) {
	var func = {
		// date is in yyyyMMdd format,
		//If noNAN is true the
		date_format_simple(dt, noNA) {
			var temp;

			//if length=8 there should only be digits
			if (dt && dt.match('^[0-9]+$') == null && dt.length == 8) {
				dt = '';
			}

			// Normalize date values
			if (dt && dt.length > 8) {
				if (dt == '0000-00-00'){
					dt = '';
				} else {
					temp = moment(dt, [
						'YYYYMMDD',
						'YYYY-MM-DD',
						'YYYY/MM/DD'
					]);
					if (temp){
						dt = temp.toString('YYYYMMDD');
					}
				}
			}

			// Return default if insufficient text to mask
			if (!dt || dt.length < 8) {
				if (!noNA) {
					return "N/A";
				} else if (!dt || dt.length == 0 || dt == "N/A"){
					return "";
				}
			}

			// Mask input as date; may return an invalid date value
			return dt.substr(4, 2) + "/" + dt.substr(6, 2) + "/" + dt.substr(0, 4);
		},

		// returns the full path to the image
		get_image(filename) {
			return window.IMAGES + filename;
		}
	};

	return func;
});
