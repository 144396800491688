/* global define */
define(['app'], function(app) {
	/**
	 * Patient portal handout
	 */
	var HandoutModel = app.Model.extend({
		type: 'barebones',
		url() {
			return "V1/portal/patients/" + this.get(this.pat_idAttribute) + "/handouts/" + this.get(this.idAttribute);
		},

		idAttribute: 'phs_id',
		cus_idAttribute: 'phs_cus_id',
		cli_idAttribute: 'phs_cli_id',
		pat_idAttribute: 'phs_pat_id',
		modifiedAttribute: 'phs_modified',

		initialize(options) {
			if (options) {
				this.set(this.idAttribute, options.phs_id);
				this.set(this.cus_idAttribute, options.phs_cus_id);
				this.set(this.cli_idAttribute, options.phs_cli_id);
				this.set(this.pat_idAttribute, options.phs_pat_id);
			}
		},

		sync(method, model, options) {
			// if (method === "read") {
			// 	options.type = "post";
			options.data = options.data || {};
			options.data[this.cus_idAttribute] = this.get(this.cus_idAttribute);
			options.data[this.cli_idAttribute] = this.get(this.cli_idAttribute);
			options.ahis = app.SessionModel.get("ahis");
			return HandoutModel.__super__.sync.apply(this, arguments);
		},

		parse(response) {
			// if(response.user){
			// 	response.user.ahis = response.ahis;
			// 	return response.user;
			// }
			return response;
		}
	});

	return HandoutModel;
});
