// Copyright 2019 Azalea Health Innovations, Inc. All Rights Reserved.

/**
Standard input widget for 8 digit. Handles custom user entry format and supplies a calender

@constructor
@param {awa.mvc.Model} [options.model] Model this input will directly interact with
@param {String} [options.modelAttribute] date attribute
@param {Boolean} [options.showTodayButton]
@extends awa.ui.InputBaseView
**/

define([
	'lib/ui/InputBaseView', 'text!tpl/date_input.htm'
], function(InputBaseView, date_input_tpl) {

	var dateInputCount = 0,
		DateInput = InputBaseView.extend({

			tagName: 'input',

			preinitialize(attributes, options) {
				// shim for our legacy Backbone Constructor behavior
				this.options = attributes || {};
			},

			render() {
				var self = this,
					attr_name = self.$el.attr(' data-model-attr-name'),
					attr_id = self.$el.attr('name');

				if (self.$el.prop('tagName') == 'INPUT' && self.$el.attr('type') != 'hidden'){
					self.$el.attr('type', 'hidden');
				}
				if (self.options.model && self.options.modelAttribute){
					self.establishModelLink();
					if (!this.options.name){
						this.options.name = this.options.modelAttribute;
					}
				}
				self.attr_id = attr_id;
				self.$input = self.$el;
				self.$input.data('view', this);
				self.$input.on('change', function(event, args) {
					if (args && args.source == self){
						return;
					}
					self._setWidgetInputs(self.$input.val());
				});
				if (_.isUndefined(self.options.showTodayButton)){
					self.options.showTodayButton = self.$input.attr('data-today');
				}
				self.$field = $(date_input_tpl({id: attr_id, name: attr_name, today: self.options.showTodayButton}));
				self.$field.on('click', '.today_btn', function() {
					self.$field.find('.date_input').val((new Date()).toString('MM/dd/yyyy'));
					self.$input.val((new Date()).toString('yyyyMMdd'));
					self.$input.trigger('change');
				});
				self.$el.before(self.$field);
				self.setElement(self.$field.filter(':not(comment)').first());
				self.$dateInput = self.$field.find('.date_input');
				if (this.options.name){
					self.$dateInput.attr('name', this.options.name);
				}
				self.$dateInput
					.attr('title', self.$input.attr('title') || "Date Input")
					.attr('id', 'awa-ui-DateInput-' + dateInputCount++).datepicker({
						changeMonth: true,
						changeYear: true,
						onSelect(date){
							var dateObj = self._convertToDate(date);
							if (dateObj){
								self.$input.val(dateObj.toString('yyyyMMdd'));
								self.$input.trigger('change', {source: self});
								self.$dateInput.val(dateObj.toString('MM/dd/yyyy'));
							} else {
								self.$input.val(date);
								self.$input.trigger('change', {source: self});
							}
						}
					});
				self.$dateInput.on('change', function() {
					var dateObj = self._convertToDate(self.$dateInput.val());
					if (dateObj){
						self.$input.val(dateObj.toString('yyyyMMdd'));
						self.$input.trigger('change', {source: self});
						self.$dateInput.val(dateObj.toString('MM/dd/yyyy'));
					} else {
						self.$input.val(self.$dateInput.val());
						self.$input.trigger('change', {source: self});
					}
				});
				if (self.$input.val()){
					self._setWidgetInputs(self.$input.val());
				}
				return this;
			},

			_setWidgetInputs: function(val){
				var self = this;
				if (val === ''){
					return self.$('.date_input').val(val);
				}
				if (!(val instanceof Date)){
					if (val.indexOf('-') > -1){
						val = Date.parse(val);
					} else {
						val = Date.parseExact(val, 'yyyyMMdd');
					}
				}
				if (val){
					self.$('.date_input').val(val.toString('MM/dd/yyyy'));
				} else {
					self.$('.date_input').val('');
				}
				return false;
			},

			disable(bool){
				this.$field.find('input').prop('disabled', bool);
			},

			_convertToDate: function(value){
				if (value) {
					return Date.parse(value) || (value.length == '6' ? Date.parseExact(value, 'MMddyy') : undefined);
				}
				return false;
			},

			establishModelLink(){
				var self = this,
					$input = self.$el;

				if (!this.options.model || !this.options.modelAttribute){
					return;
				}
				$input.val(this.options.model.get(this.options.modelAttribute));
				$input.on('change', function(e, args) {
					var submit = {};
					if (args && args.source == self.options.model){
						return;
					}
					submit[self.options.modelAttribute] = e.currentTarget.value;
					self.options.model.set(submit, {source: self});
				});
				this.listenTo(this.options.model, 'change:' + this.options.modelAttribute, function(model, val, args) {
					if (args && args.source == self){
						return;
					}
					$input.val(self.options.model.get(self.options.modelAttribute));
					$input.trigger('change', {source: self.options.model});
				});

			}

		});
	return DateInput;
});
