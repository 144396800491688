define(['app', 'views/SignatureView', 'views/VariableReplacementView'], function(app, SignatureView, VariableReplacementView) {
	var HandoutView = app.View.extend({
		el: '#handout',
		template: 'handout',

		events: {
			"click #cancel_handout": "onCancel",
			"click #send_handout": "onSendHandout",
			cancel_handout: "onCancel",
			send_handout: "onSendHandout",
			"click .signature_button, .signature-signed": "onSign",
			"click .timestamp_button": "onTimestamp",
			"click #handout_controls_toggle": "onToggleControls"
		},

		replacerMatchPattern: new RegExp(VariableReplacementView.matchPatternString, 'g'),

		initialize(options) {
			if (options) {
				this.hideControls = options.hideControls;
			}
		},

		postrender() {
			if (this.hideControls){
				var $controls = this.$el.find('#handout_controls_contents');
				$controls.hide();
			}
			this.variableReplacement();
			this.$el.show();
		},

		onToggleControls() {
			var $controls = this.$el.find('#handout_controls_contents'),
				$toggle = this.$el.find('#handout_controls_toggle');

			if ($controls.is(":hidden")) {
				$controls.show();
				$toggle.text('Hide Handout Controls');
			} else {
				$controls.hide();
				$toggle.text('Show Handout Controls');
			}
		},

		onCancel() {
			this.trigger('cancelled');
			this.destroy();
		},

		onSendHandout() {
			var html = this.getHandoutContents();
			this.$el.find(".temp-disabled").removeAttr('disabled').removeClass('temp-disabled');
			$('#handout_contents').focus();

			if (html){
				this.trigger('send', {
					phs_content: html
				});
			}

		},

		//traverses the handout and gets values of all input, select, and textarea elements
		getHandoutContents() {
			var self = this, $handout, $handoutSelects, $handoutTextareas;

			//persist and correct values before cloning dom
			self.$('select').each(function(parentIndex, parentEl) {
				//selects are built with no values or the same
				//use option text instead to select
				var current_selection = $(parentEl).find(':selected').text(), i;
				if (!_.isArray(current_selection) && !_.isUndefined(current_selection)) {
					$(parentEl).children().each(function(index, el) {
						if ($(el).text() !== current_selection) {
							$(el).removeAttr('selected');
						} else if ($(el).text() === current_selection) {
							$(el).attr('selected', 'selected');
						}
					});
				//multiple select field
				} else {
					$(parentEl).children().each(function(index, el) {
						$(el).removeAttr('selected');
					});
					$(parentEl).children().each(function(index, el){
						for (i = 0; i < current_selection.length; i++) {
							if ($(el).text() === current_selection[i]) {
								$(el).attr('selected', 'selected');
							}
						}
					});
				}
			});

			self.$('input[type=text], input[type=hidden], input[type=password]').each(function(index, el) {
				$(el).attr("value", $(el).val());
			});
			self.$('input[type=checkbox], input[type=radio]').each(function(index, el) {
				if (el.checked) {
					$(el).attr("checked", true);
				} else {
					$(el).get(0).removeAttribute("checked");
				}
			});
			self.$('textarea').each(function(index, el) {
				$(el).html(el.value);
			});


			//cloneNode does not carry over the state of selects
			$handout = $($('#handout_contents')[0].cloneNode(true));
			//http://stackoverflow.com/questions/742810/clone-isnt-cloning-select-values
			//http://api.jquery.com/clone/
			$handoutSelects = $handout.find("select");
			self.$('select').each(function(index, el) {
				$handoutSelects.eq(index).val($(el).val());
			});
			$handoutTextareas = $handout.find("textarea");
			self.$('textarea').each(function(index, el) {
				$handoutTextareas.eq(index).html($(el).val());
			});

			// Don't save autocomplete attr
			$handout.find(':input').each(function(index, el) {
				$(el).removeAttr("autocomplete");
			});

			// Beware! Witchcraft happens in the next line. (setting the $el of
			// a view that has not been rendered yet).
			if (self.variableReplacer){
				self.variableReplacer.$el = $handout;
				$handout.html(self.variableReplacer.transformText({remove_inline: true}));
			}

			return $handout.html();
		},

		//replaces variables.....whatever
		variableReplacement() {
			var self = this,
				handout_contents = $('#handout_contents').html();

			if (self.replacerMatchPattern.test(handout_contents)) {
				/****************************************************************************************
				 The editor popup will be triggered by the following variables: [[??]], [[##]], [[date]]
				****************************************************************************************/
				self.variableReplacer = new VariableReplacementView({
					text: handout_contents
				});
				handout_contents = self.variableReplacer.transformText({
					add: true
				});
			}
			self.replacerMatchPattern.lastIndex = 0;
			//Autofill non-editable variables
			$('#handout_contents').html(handout_contents);

			// Disable autocomplete for all form inputs for privacy reasons
			$(':input').each(function(index, el) {
				$(el).attr('autocomplete', 'off');
			});

			self.widgets();
		},


		onSign(e) {
			/*If signature view already exists clear the signature box and hot swap the button.
			* SignatureView.button determines where the signature is saved so swapping it avoids having to
			* make a new SignatureView.
			*/
			if (this.signatureView){
				this.signatureView.onClear();
				this.signatureView.options.button = $(e.target);
			} else {
				var signatureView = new SignatureView({
					button: $(e.target)
				});
				// render signature view
				this.$el.find("#signature_box").html(signatureView.render().el);
				this.signatureView = signatureView;
				this.signatureView.on("cancelled", () => {
					delete this.signatureView;
				});
			}
		},

		onTimestamp(e) {
			var el = $(e.target), now = new Date(), ampm, hours, minutes;
			hours = now.getHours();
			minutes = String(now.getMinutes()).padStart(2, '0');
			ampm = (hours > 11) ? "PM" : "AM";

			el.replaceWith(app.SessionModel.get('usr_fname') + ' ' + app.SessionModel.get('usr_lname') + ' at ' +
				(now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' +
				(hours > 12 ? hours - 12 : hours) + ':' + minutes + ' ' + ampm);
		}
	});
	return HandoutView;
});
