define(['backbone'], function(Backbone) {
	var ViewManager = _.extend({
		activeViews: {},

		add(name, view) {
			this.activeViews[name] = view;
		},

		remove(name) {
			var view = this.activeViews[name];

			if (!view) {
				return;
			}

			view.destroy();
			delete this.activeViews[name];
		},

		isActive(name) {
			return !_.isUndefined(this.activeViews[name]);
		},

		reset() {
			var self = this;
			_.each(this.activeViews, function(view, index) {
				self.remove(index);
			});
		},

		get(name) {
			return this.activeViews[name];
		}
	}, Backbone.Events);

	return ViewManager;
});
