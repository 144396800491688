// Copyright 2019 Azalea Health Innovations, Inc.
// Partial implementation of awa/widgets.js

define([
	'lib/ui/DateInput', 'lib/ui/Dateselector',
	'lib/ui/SearchableSelect', 'lib/ui/SearchableMultiSelect', 'lib/View'],
function(DateInput, Dateselector,
	SearchableSelect, SearchableMultiSelect, View) {
	var widgets = {};
	widgets.DateInput = DateInput;
	widgets.dateselector = Dateselector;
	widgets["select.searchable"] = SearchableSelect;
	widgets["select.multiselect"] = SearchableMultiSelect;

	View.setWidgets(widgets);
	return widgets;
});
