// Copyright 2019 Azalea Health Innovations, Inc. All Rights Reserved.

/**
Used for boolean type attributes (CHAR(1))

@class awa.ui.InputBaseView
@constructor
@param {awa.mvc.Model} [options.model]
@param {String} [options.modelAttribute] attribute of the model to bind to
@extends Backbone.View
**/

define(['backbone'], function(Backbone) {

	var InputBaseView = Backbone.View.extend({

		preinitialize(attributes, options) {
			// shim for our legacy Backbone Constructor behavior
			this.options = attributes || {};
		},

		generateElementID(){
			return (this.el.id = _.uniqueId('ibv_'));
		},

		setElementID(id){
			this.$el.attr('id', id);
			return this;
		},

		getElementID(){
			return this.el.id || this.generateElementID();
		},

		/**
		disables the "input". useful to override for input groups
		@param {Boolean} disable Flag
		@method disable
		@return {awa.ui.InputBaseView} this
		*/
		disable(disable){
			this.$el.attr('disabled', disable);
			return this;
		},

		/**
		hides the view in the most effecient way possible
		@method hide
		@return {awa.ui.InputBaseView} this
		*/
		hide(){
			this.$el.addClass('hide');
			return this;
		},

		/**
		shows the view
		@method show
		@return {awa.ui.InputBaseView} this
		*/
		show(){
			this.$el.removeClass('hide');
			return this;
		},

		/**
		Gives the input focus. useful for group inputs
		@method hide
		@return {awa.ui.InputBaseView} this
		*/
		focus(){
			this.$el.focus();
			return this;
		}
	});

	return InputBaseView;

});
