// Copyright 2019 Azalea Health Innovations, Inc.
// import calendarIcon from '../../../images/calendar16.png';

define(['backbone', 'jquery', 'underscore', 'lib/func', 'jquery-ui'],
(Backbone, $, _, func) => {
	let Dateselector_ids = 0;
	const Dateselector = Backbone.View.extend({
		preinitialize(attributes, options) {
			// shim for our legacy Backbone Constructor behavior
			if (attributes && !attributes.el){
				this.options = {el: attributes};
			}
		},
		render(options, showCalendar) {
			const self = this;
			let $outer_box,
				$cal_icon;

			if (_.isUndefined(showCalendar)){
				showCalendar = true;
			}

			if (!self.$el.attr('id')){
				self.$el.attr('id', 'awa-ui-Dateselector=' + Dateselector_ids++);
			}

			self.$el.datepicker({
				onSelect(d, i) {
					if (d !== i.lastVal){
						$(this).change();
					}
				}
			});

			self.$el.on("blur", () => {
				let dates = self.$el.val(),
					format = "",
					org = self.$el.val(),
					size = self.$el.val().length,
					datesMonth = dates.substring(0, 2),
					datesDay = dates.substring(2, 4),
					datesYear = dates.substring(4);

				if (size >= 5 && size < 9){
					if (dates.indexOf("/") < 0){
						dates = datesMonth + "/" + datesDay + "/" + datesYear;
					}
					format = new Date(dates);
					if (format != "Invalid Date"){
						self.$el.val(new Date(dates).toString("MM/dd/yyyy"));
					}
				} else {
					self.$el.val(org);
				}
			});
			// Create wrapping div
			$outer_box = $('<div class="inline-block">').css({
				position: "relative"
			});

			if (!self.$el.hasClass("widget") || self.$el.attr("data") !== 'date:compact') {
				self.$el.attr("data", "date:compact");
			}

			self.$el.wrap($outer_box);

			$cal_icon = $('<span title="Click to see calendar"></span>').css({
				background: "white url('" + func.get_image('calendar16.png') + "') center right no-repeat",
				padding: "0.25rem",
				position: "absolute",
				right: "0.5rem",
				top: "0.25rem",
				width: "1rem",
				height: "1rem"
			}).addClass("awa-ui-dateselector-icon");

			$cal_icon.bind("click", function() {
				if (!self.$el.is(':disabled')){
					self.$el.datepicker('show', true);
				}
			});

			if (showCalendar){
				self.$el.css({
					"padding-right": "2rem"
				});
				self.$el.after($cal_icon);

			}

			if (!_.isUndefined(options)){
				self.applyOptions(options);
			}
		},
		//dont do this fix later
		applyOptions(options) {
			var self = this;
			self.$el.datepicker("option", options);
		},

		setDatepickerOptions(options) {
			var self = this;
			self.$el.datepicker("option", options);
		},


		setDate(date) {
			if (date) {
				this.$el.val(func.date_format_simple(date));
			}
		},

		getDate() {
			var d = Date.parse(this.$el.val());
			if (d) {
				return d.toString("yyyyMMdd");
			}
			return "";
		},

		clearDate(){
			this.$el.val('');
		}
	});

	return Dateselector;

});
