define(['app'], function(app) {
	var AlertView = app.View.extend({
		el: '#alert',

		initialize() {
			var self = this;

			this.render();
			this.listenTo(this, 'error', function(text) {
				self.$el.removeClass('error success');
				self.$el.addClass('error').text(text).slideDown(400).delay(4000).slideUp(400);
			});
			this.listenTo(this, 'success', function(text) {
				self.$el.removeClass('error success');
				self.$el.addClass('success').text(text).slideDown(400).delay(4000).slideUp(400);
			});
		}
	});
	return AlertView;
});
