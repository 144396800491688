// Copyright 2019 Azalea Health Innovations, Inc.

define(['backbone'], function(Backbone) {

	var SearchableSelect,
		bodyElem,
		closeAllDropdowns;

	// save a reference to the body element
	bodyElem = document.body || document.getElementsByTagName("body")[0];

	// method to close all open dropdowns in the DOM
	closeAllDropdowns = function() {
		var nodelist;

		nodelist = $('.awa-ui-searchable-select-wrapper');
		_.each(nodelist, function(node) {
			var $node = $(node);
			$node = $node.find('.awa-ui-searchable-select-dropdown.hide');
			if ($node.length == 0) {
				// Closing the dropdown via a click event allows the dropdown to wrap up
				// any internal status before closing.
				$(node).find('.awa-ui-searchable-select-overlay').trigger('click');
			}
		});
	};

	/**
	 * @class SearchableSelect
	 * @extends Backbone.View
	 */
	SearchableSelect = Backbone.View.extend({

		preinitialize(attributes, options) {
			// shim for our legacy Backbone Constructor behavior
			this.options = attributes || {};
		},

		events: {
			'click .awa-ui-searchable-select-overlay': 'onSelectClick',
			'keydown .awa-ui-searchable-select-overlay': 'onSelectKeydown',
			'click .awa-ui-searchable-select-dropdown': 'onDropdownClick',
			'keydown .awa-ui-searchable-select-dropdown': 'onDropdownKeydown',
			'keyup input': 'onSearch'
		},

		destroy() {
			this.willClose();
		},

		render() {
			var selectElem = this.el,
				wrapperElem,
				overlayElem,
				dropElem,
				parent, sibling;

			// create the wrapper element for the <select>
			wrapperElem = document.createElement("div");
			wrapperElem.classList.add("awa-ui-searchable-select-wrapper");

			// wrap the <select> with the wrapper <div>
			parent = selectElem.parentNode;
			sibling = selectElem.nextSibling;
			wrapperElem.appendChild(selectElem);
			if (sibling) {
				parent.insertBefore(wrapperElem, sibling);
			} else {
				parent.appendChild(wrapperElem);
			}

			// create the overlay element to mask the <select>
			// the overlay will receive click and keyboard focus
			overlayElem = document.createElement("div");
			overlayElem.classList.add("awa-ui-searchable-select-overlay");
			overlayElem.setAttribute("tabindex", "0");
			if (selectElem.getAttribute('title') == null) {
				overlayElem.setAttribute("title", "");
			} else {
				overlayElem.setAttribute("title", selectElem.getAttribute('title'));
			}
			wrapperElem.appendChild(overlayElem);
			// create a dummy container for the drop down
			dropElem = document.createElement("div");
			dropElem.classList.add("awa-ui-searchable-select-dropdown");
			dropElem.classList.add("hide");
			wrapperElem.appendChild(dropElem);

			// <select> does not receive keyboard focus directly
			selectElem.setAttribute("tabindex", "-1");

			if (this.options.disabled) {
				selectElem.disabled = _.isFunction(this.options.disabled)
					? this.options.disabled()
					: true;
			}

			//Add a listener for change events
			selectElem.addEventListener("change", this.reset.bind(this));
			selectElem.addEventListener("inbound-change", this.reset.bind(this));

			// cache DOM references
			this.selectElem = selectElem;
			this.$selectElem = $(selectElem);

			this.wrapperElem = wrapperElem;
			this.overlayElem = overlayElem;
			this.dropElem = dropElem;

			// set the view's root element to the wrapper and delegate events to it
			this.el = wrapperElem;
			this.$el = $(wrapperElem);
			this.delegateEvents();
		},

		getDropdownElement() {
			return this.dropElem;
		},

		/*
		 * Resets the drop down is built status to false. This will force the
		 * widget to rebuild the drop down the next time it is opened.
		 *
		 * NOTE:
		 * You should call this method if you programmatically alter the <select>'s
		 * options list after this widget has already been rendered in order
		 * to keep these in sync. The widget will not do it automatically. Sorry.
		 */
		reset() {
			this.dropBuilt = false;
		},

		open() {
			this.willOpen();
			this.dropElem.classList.remove("hide");
			this.trigger('opened');
			if (this.searchElem){
				this.searchElem.focus();
			}
		},

		close() {
			this.willClose();
			this.dropElem.classList.add("hide");
			this.trigger('closed');
		},

		toggle() {
			if (this.isOpen()) {
				return this.close();
			}
			return this.open();
		},

		isOpen() {
			return !this.dropElem.classList.contains("hide");
		},

		willOpen() {
			var rect, top, left;
			closeAllDropdowns();
			bodyElem.addEventListener("click", closeAllDropdowns);
			this.buildDropdown();
			/* keeping until absolute positioning is corrected */
			rect = this.el.getBoundingClientRect();
			top = parseInt(this.dropElem.style.top);
			left = parseInt(this.dropElem.style.left);
			if (top !== Math.floor(rect.top + (rect.height || 27))){
				this.dropElem.style.top = rect.top
					+ (rect.height || 27);//  + "px";
			}
			if (left !== Math.floor(rect.left)){
				this.dropElem.style.left = rect.left;// + "px";
			}
			// this.dropElem.style.background = "blue";
		},

		willClose() {
			bodyElem.removeEventListener("click", closeAllDropdowns);
		},

		didSelect(selectedElem) {
			var evt, selection;
			// if we have an existing selection, remove the highlight
			if (this.curSelectionElem) {
				this.curSelectionElem.classList.remove("selected");
			}
			// get the selection from the parent <li>
			this.curSelectionElem = selectedElem;
			this.curSelectionElem.classList.add("selected");
			selection = this.curSelectionElem.getAttribute("data-value");
			// set the real <select> to the new selection
			this.selectElem.value = selection;
			// close the drop down
			this.close();
			// clear out the search field
			this.searchElem.value = "";
			this.restoreItems();
			// return focus to the select overlay
			this.overlayElem.focus();
			// fire a custom change event on the <select>
			evt = new window.CustomEvent("change", {
				bubbles: true,
				cancelable: true
			});
			this.selectElem.dispatchEvent(evt);
			evt = new window.CustomEvent("outbound-change", {
				bubbles: true,
				cancelable: true
			});
			this.selectElem.dispatchEvent(evt);
		},

		buildDropdown() {
			var ch = this.ch,
				selectElem = this.selectElem,
				origValue = selectElem.value || selectElem.getAttribute('value') || selectElem.getAttribute('data-selected'),
				list = [],
				selectOptions,
				itemElem,
				text,
				value,
				param,
				i, len, option;

			if (this.dropBuilt) {
				// make sure that the current select value is reflected in the
				// drop down. it may have changed since we first built it.
				itemElem = this.dropElem.querySelector(".selected");
				if (itemElem && itemElem.getAttribute("data-value") != origValue) {
					itemElem.classList.remove("selected");
				}
				itemElem = this.dropElem.querySelector('[data-value="' + origValue + '"]');
				if (itemElem) {
					itemElem.classList.add("selected");
				}
				return;
			}

			// loop over the <select> element's options and build an unordered
			// list to display in the drop down
			selectOptions = selectElem.querySelectorAll('option');

			for (i = 0, len = selectOptions.length; i < len; i++) {
				option = selectOptions.item(i);
				text = option.textContent || option.innerText || '';
				value = option.value;
				param = {};

				if (option.getAttribute("data-meta")) {
					text += '<br/><small>' + option.getAttribute("data-meta") + '</small>';
				}

				param = {
					'data-name': text.toLowerCase(),
					'data-value': value
				};
				if (option.value == origValue) {
					param.class = 'selected';
				}
				if (option.getAttribute("data-class")) {
					param.class += ' ' + option.getAttribute("data-class");
				}
				if (option.getAttribute("data-class") == 'separator'){
					text += '<hr>';
					param.disabled = 'disabled';
				}
				list.push(
					ch('li', param, ch(
						'button',
						{type: 'button'},
						text
					))
				);
			}

			$(this.dropElem).empty();
			$(this.dropElem).append([
				ch('div', {class: 'search-container'}, ch(
					'input', {
						title: this.selectElem.getAttribute('title'),
						type: 'text',
						placeholder: 'Search...',
						autocomplete: 'off'
					},
					''
				)),
				ch('ul', {}, list)
			]);
			this.listElem = this.dropElem.querySelector('ul');
			this.curSelectionElem = selectElem.querySelector('li.selected');
			this.searchContainerElem = this.dropElem.querySelector('.search-container');
			this.searchElem = this.dropElem.querySelector('.search-container input');
			this.dropBuilt = true;
		},

		getSearchContainerElement(){
			return this.searchContainerElem;
		},

		onDropdown() {
			if (!this.selectElem.disabled) {
				this.toggle();
			}
		},

		onSelectClick(evt) {
			this.onDropdown();
			evt.stopPropagation();
			return false;
		},

		onSelectKeydown(evt) {
			if (evt.keyCode == 13 || evt.keyCode == 32 || evt.keyCode == 40) {
				// ENTER || SPACE || DOWN ARROW
				return this.onDropdown();
			}
			if (evt.keyCode == 27 || evt.keyCode == 9) {
				// ESC || TAB
				return this.close();
			}
			return undefined;
		},

		onItemFocus(evt) {
			this.curFocusItem = evt.target.parentNode;
		},

		onItemBlur() {
			this.curFocusItem = null;
		},

		onDropdownClick(evt) {
			evt.stopPropagation();
			if (evt.target.tagName == "BUTTON") {
				this.didSelect(evt.target.parentNode);
			}
		},

		onDropdownKeydown(evt) {
			var item;
			if (evt.keyCode == 27) {
				// ESC
				this.close();
				// return focus to the select overlay
				this.overlayElem.focus();
				// stop propagation
				evt.stopImmediatePropagation();
				return false;
			} else if (evt.keyCode == 38) {
				// UP ARROW
				if (!this.curFocusItem) {
					// set focus to the last element in the list that is
					// not hidden
					item = this.listElem.lastElementChild;
					while (item) {
						if (!item.classList.contains("hide")) {
							item.firstElementChild.focus();
							this.curFocusItem = item;
							break;
						}
						item = item.previousElementSibling;
					}
				} else if (this.curFocusItem.previousElementSibling) {
					// get the previous visible element in the list
					item = this.curFocusItem.previousElementSibling;
					while (item) {
						if (!item.classList.contains("hide")) {
							item.firstElementChild.focus();
							this.curFocusItem = item;
							break;
						}
						item = item.previousElementSibling;
					}
					if (!item) {
						// didn't find one, put focus back in search box
						this.curFocusItem = null;
						this.searchElem.focus();
					}
				} else {
					// wrap around to the bottom
					this.curFocusItem = null;
					this.searchElem.focus();
				}

				// don't allow the browser to scroll the view
				return false;
			}
			if (evt.keyCode == 40) {
				// DOWN ARROW
				if (!this.curFocusItem) {
					// set focus to the first element in the list that is
					// not hidden
					item = this.listElem.firstElementChild;
					while (item) {
						if (!item.classList.contains("hide")) {
							item.firstElementChild.focus();
							this.curFocusItem = item;
							break;
						}
						item = item.nextElementSibling;
					}
				} else if (this.curFocusItem.nextElementSibling) {
					// get the next visible element in the list
					item = this.curFocusItem.nextElementSibling;
					while (item) {
						if (!item.classList.contains("hide")) {
							item.firstElementChild.focus();
							this.curFocusItem = item;
							break;
						}
						item = item.nextElementSibling;
					}
					if (!item) {
						// didn't find one, put focus back in search box
						this.curFocusItem = null;
						this.searchElem.focus();
					}
				} else {
					// wrap around to the top
					this.curFocusItem = null;
					this.searchElem.focus();
				}

				// don't allow the browser to scroll the view
				return false;
			}
			if (evt.keyCode == 9){
				//TAB
				this.close();
				evt.stopImmediatePropagation();
				return false;
			}
			return undefined;
		},

		onSearch(evt) {
			var target = evt.currentTarget,
				value = target.value.toLowerCase(),
				item;

			if (value == "") {
				// restore all items
				this.restoreItems();
			} else {
				// show those that match the search text
				item = this.listElem.firstElementChild;
				while (item) {
					if (item.getAttribute("data-name").indexOf(value) === -1) {
						item.classList.add("hide");
					} else {
						item.classList.remove("hide");
					}
					item = item.nextElementSibling;
				}
			}
		},

		restoreItems() {
			var item = this.listElem.firstElementChild;
			while (item) {
				item.classList.remove("hide");
				item = item.nextElementSibling;
			}
		},

		selectedValue(){
			return this.selectElem.value;
		},

		selectedText(){
			return this.selectElem.options[this.selectElem.selectedIndex].textContent;
		},

		selected(){
			return {
				value: this.selectedValue(),
				text: this.selectedText()
			};
		},

		//This is not equivalent to selectedValue above, because it can also set the value.
		val(){
			return this.$selectElem.val(...arguments);
		},


		/**
		 * COPIED FROM "tpl.js"
		 *
		 * Similar to React's createElement function, this provides a js friendly way to create html
		 * without raw strings. This was developed prior to template literals so use at your own discretion.
		 *
		 * @method ch
		 * @param {String} tagName Tag name of html element
		 * @param {Object|String} [props] attribute and properties to include. String is converted to a prop
		 * 	If value is a boolean, it will be treated as a prop. i.e. "checked"
		 * @param {String|Array} [children] String or array of html string to include in the element.
		 * 	undefined && null is converted to an empty string. numbers, including 0, are converted to strings.
		 * @returns {String} computed html as string
		 */
		ch(tagName, props, children){
			var propsKey, attributes;
			if (!Array.isArray(children)){
				children = [_.isUndefined(children) || children === null ? '' : children];
			}
			if (_.isString(props)){
				propsKey = props;
				props = {};
				props[propsKey] = true;
			}
			attributes = _.map(props, function(val, attr) {
				if (_.isUndefined(val) || val === null || val === false){
					return '';
				}
				if (val === true){
					return attr;
				}
				return attr + '="' + val + '"';
			}).join(' ');
			if (attributes.length){
				attributes = ' ' + attributes;
			}
			return '<' + tagName
				+ attributes
				+ '>'
				+ children.join('')
				+ '</' + tagName + '>';
		}
	});

	return SearchableSelect;
});
