// Copyright 2019 Azalea Health Innovations, Inc.

define(['backbone', 'lib/ui/SearchableSelect'], function(Backbone, SearchableSelect) {
	/**
	 * @class SearchableMultiSelect
	 * @extends awa.ui.SearchableSelect
	 * @constructor
	 * @param {Array} [options.value] array of ids to set multiselect to when ready
	 * @param {Boolean} [options.verboseDummyOption] build grammatical dummy option
	 * Changes the selected display from "Sel1 (2 more)" to "Sel1, Sel2, and Sel3".
	 */
	var SearchableMultiSelect = SearchableSelect.extend({

		preinitialize(attributes, options) {
			// shim for our legacy Backbone Constructor behavior
			this.options = attributes || {};
		},

		events: {
			'click .awa-ui-searchable-select-overlay': 'onSelectClick',
			'keydown .awa-ui-searchable-select-overlay': 'onSelectKeydown',
			'click .awa-ui-searchable-multiselect-dropdown': 'onDropdownClick',
			'keydown .awa-ui-searchable-multiselect-dropdown': 'onDropdownKeydown',
			'keyup input': 'onSearch'
		},

		render() {
			var self = this,
				selectElem = this.el,
				dummySelectElem,
				dummySelectOption,
				wrapperElem,
				overlayElem,
				dropElem,
				parent, sibling;

			this.options.defaultSelection = this.options.defaultSelection
				|| selectElem.getAttribute("data-selected")
				|| "";

			// create the wrapper element for the <select>
			wrapperElem = document.createElement("div");
			wrapperElem.classList.add("awa-ui-searchable-select-wrapper");

			// wrap the <select> with the wrapper <div>
			parent = selectElem.parentNode;
			sibling = selectElem.nextSibling;
			wrapperElem.appendChild(selectElem);
			if (sibling) {
				parent.insertBefore(wrapperElem, sibling);
			} else {
				parent.appendChild(wrapperElem);
			}

			// create the overlay element to mask the <select>
			// the overlay will receive click and keyboard focus
			overlayElem = document.createElement("div");
			overlayElem.classList.add("awa-ui-searchable-select-overlay");
			overlayElem.setAttribute("tabindex", "0");
			overlayElem.setAttribute("title", selectElem.getAttribute('title') || self.options.tip || '');
			wrapperElem.appendChild(overlayElem);

			// create a dummy container for the drop down
			dropElem = document.createElement("div");
			dropElem.classList.add("awa-ui-searchable-select-dropdown");
			dropElem.classList.add("awa-ui-searchable-multiselect-dropdown");
			dropElem.classList.add("hide");
			// dropElem.style.background = "red";
			wrapperElem.appendChild(dropElem);

			//Clone the original select into a dummy select
			//it'll be used to display multiple choices
			dummySelectElem = document.createElement("select");
			dummySelectElem.setAttribute("select2", "false");
			dummySelectElem.style.width = "auto";

			dummySelectOption = document.createElement("option");
			dummySelectOption.value = "";
			dummySelectOption.text = this.options.defaultSelection;
			dummySelectElem.appendChild(dummySelectOption);
			wrapperElem.appendChild(dummySelectElem);

			//Hide the original select
			selectElem.setAttribute('multiple', 'true');
			selectElem.classList.add("hide");

			//Add an event listener for select updates
			$(selectElem).on("outbound-change", function() {
				self.buildDummyOption();
			});
			$(selectElem).on("change", function() {
				self.buildDropdown();
				self.buildDummyOption();
			});
			$(selectElem).on("inbound-change", function() {
				self.buildDropdown();
				self.buildDummyOption();
			});

			// cache DOM references
			this.selectElem = selectElem;
			this.$selectElem = $(selectElem);
			this.dummySelectElem = dummySelectElem;
			this.dummySelectOption = dummySelectOption;
			this.wrapperElem = wrapperElem;
			this.overlayElem = overlayElem;
			this.dropElem = dropElem;

			// set the view's root element to the wrapper and delegate events to it
			this.el = wrapperElem;
			this.$el = $(wrapperElem);
			this.delegateEvents();
		},

		didSelect(selectedElem) {
			var event, selection, val, defaultItem, defaultVal;

			this.curSelectionElem = selectedElem;
			selection = this.curSelectionElem.getAttribute("data-value");
			val = $(this.selectElem).val();
			val = val === null ? [] : val;

			if (this.curSelectionElem.classList.contains("selected")){
				//Item was de-selected, remove it from the original select
				//and remove the highlight
				this.curSelectionElem.classList.remove("selected");
				if (val.indexOf(selection) >= 0){
					val.splice(val.indexOf(selection), 1);
				}
			} else {
				//Item was selected, add it to the original select
				//and give it a highlight
				this.curSelectionElem.classList.add("selected");
				val.push(selection);
			}

			// If there is a default selection
			if (this.options.defaultSelection){
				// Get the item corresponding to that selection in the select
				defaultItem = this.dropElem.querySelector('li[data-name="' + this.options.defaultSelection.toLowerCase() + '"]');
				// If there is an option corresponding to the default, AND
				// it is hidden (meaning it is to be used as the default, but
				// should not be visible)
				if (defaultItem && _.contains(defaultItem.classList, "hide")){
					defaultVal = defaultItem.getAttribute("data-value");
					// When no more items are selected, select the default
					if (val.length == 0){
						val.push(defaultVal);
					// Otherwise remove the default from the list of selected items
					} else if (val.length == 2 && _.contains(val, defaultVal)){
						val.splice(val.indexOf(defaultVal), 1);
					}
				}
			}

			$(this.selectElem).val(val);

			// fire a custom change event on the <select>
			event = new window.CustomEvent("change", {
				bubbles: true,
				cancelable: true
			});
			this.selectElem.dispatchEvent(event);
			event = new window.CustomEvent("outbound-change", {
				bubbles: true,
				cancelable: true
			});
			this.selectElem.dispatchEvent(event);
		},

		buildDummyOption(){
			var selected = this.selectedText(),
				option, del;

			//No item chosen, use the default text
			if (_.isEmpty(selected) || !this.dropBuilt){
				this.dummySelectOption.text = this.options.defaultSelection;
				return;
			}

			option = selected[0];

			if (selected.length > 1){
				if (this.options.verboseDummyOption) {
					selected[selected.length - 1] = "and " + selected[selected.length - 1];
					del = selected.length > 2 ? ', ' : ' ';
					option = selected.join(del);
				} else {
					//Add the number of other items in the list
					option += " (" + (selected.length - 1) + " more)";
				}
			}

			//Finally, update the option's text
			this.dummySelectOption.text = option;

		},

		buildDropdown() {
			var selectElem = this.selectElem,
				origValues = $(selectElem).val(),
				dropElem = this.dropElem,
				wrapperElem,
				listElem,
				itemElem,
				buttonElem,
				text,
				i, len, option;

			if (origValues == null){
				origValues = [];
			}

			if (this.dropBuilt) {
				// make sure that the current select value is reflected in the
				// drop down. it may have changed since we first built it.
				_.each(dropElem.querySelectorAll('li'), function(item) {
					if (origValues.indexOf(item.getAttribute('data-value')) >= 0){
						item.classList.add('selected');
					} else {
						item.classList.remove('selected');
					}
				});
				return;
			}

			wrapperElem = this.buildDropdownWrapper();

			// loop over the <select> element's options and build an unordered
			// list to display in the drop down
			listElem = document.createElement("ul");
			for (i = 0, len = selectElem.options.length; i < len; i++) {
				option = selectElem.options.item(i);
				text = option.textContent || option.innerText;
				if (option.getAttribute("data-meta")) {
					text += '<br/><small>' + option.getAttribute("data-meta") + '</small>';
				}
				if (option.getAttribute("data-class") == 'separator'){
					text += '<hr>';
					itemElem.setAttribute("disabled", "disabled");
				}
				itemElem = document.createElement("li");
				itemElem.setAttribute("data-name", text.toLowerCase());
				itemElem.setAttribute("data-value", option.value);
				if (origValues.indexOf(option.value) >= 0) {
					itemElem.classList.add("selected");
					this.curSelectionElem = itemElem;
				}
				if (option.getAttribute("data-class")) {
					itemElem.classList.add(option.getAttribute("data-class"));
				}
				if (option.getAttribute("data-hidden")) {
					itemElem.classList.add("hide");
				}
				buttonElem = document.createElement("button");
				buttonElem.type = "button";
				buttonElem.innerHTML = text;
				buttonElem.addEventListener("focus", this.onItemFocus.bind(this));
				buttonElem.addEventListener("blur", this.onItemBlur.bind(this));
				itemElem.appendChild(buttonElem);
				listElem.appendChild(itemElem);
			}
			this.listElem = listElem;

			this.dropElem.innerHTML = "";
			this.dropElem.appendChild(wrapperElem);
			this.dropElem.appendChild(listElem);

			this.dropBuilt = true;
		},

		buildDropdownWrapper() {
			var wrapperElem;
			wrapperElem = document.createElement("div");
			wrapperElem.classList.add("search-container");
			wrapperElem.appendChild(
				this.buildSearchField()
			);
			return wrapperElem;
		},

		buildSearchField() {
			var searchElem;
			searchElem = document.createElement("input");
			searchElem.title = this.selectElem.getAttribute('title');
			searchElem.type = "text";
			searchElem.setAttribute("placeholder", "Search...");
			//non-standard but the only other way to prevent is to remove the name of the element.
			searchElem.setAttribute("autocomplete", "off");
			this.searchElem = searchElem;
			return searchElem;
		},

		selectedValue(){
			var selectedValue = [],
				selectedOptions = this.selectElem.selectedOptions;

			_.each(selectedOptions, function(item) {
				selectedValue.push(item.getAttribute('value'));
			});

			return selectedValue;
		},

		selectedText(){
			var selectedText = [],
				selectedOptions = this.selectElem.selectedOptions;

			_.each(selectedOptions, function(item) {
				selectedText.push(item.textContent);
			});

			return selectedText;
		},

		selected(){
			var selected = [],
				selectedOptions = this.selectElem.selectedOptions;

			_.each(selectedOptions, function(item) {
				selected.push({
					value: item.getAttribute('value'),
					text: item.textContent
				});
			});

			return selected;
		},

		setDefaultSelection(newSelection){
			this.options.defaultSelection = newSelection;
			this.reset();
			this.buildDummyOption();
		},

		//This is not equivalent to selectedValue above, because it can also set the value.
		val(){
			var retval;
			retval = this.$selectElem.val(...arguments);
			this.buildDropdown();
			this.buildDummyOption();
			return retval;
		}

	});

	return SearchableMultiSelect;
});
