define(['app', 'models/SessionModel', 'views/LoginView', 'views/WaitView', 'views/ModalView', 'oidc-client'],
(app, SessionModel, LoginView, WaitView, ModalView, Oidc) => {
	Oidc.Log.logger = console;
	Oidc.Log.level = Oidc.Log.INFO;

	// SSO constants defined in .env; webpack.common attempts to divine reasonable defaults
	let Router = Backbone.Router.extend({
		preinitialize(options) {
			this.options = options || {};
			this.env = this.options.env;
		},

		userManager: undefined,

		routes: {
			"": "ssoLogin",
			sso: "ssoLogin",
			"sso/callback": "ssoCallback",
			":accountId/:resourceId": "modal"
		},

		initialize() {
			const self = this;
			this.env = this.options.env;
			const ssoConfig = {
				authority: this.env.__SSO_BASE_URI__,
				client_id: this.env.__SSO_CLIENT_ID__,
				// Redirect URI should end in #sso/callback
				redirect_uri: this.env.__BASE_URL__ + this.env.__SSO_REDIRECT_URI__,
				response_type: 'code',

				scope: 'openid email profile',

				automaticSilentRenew: false,
				validateSubOnSilentRenew: true,

				filterProtocolClaims: true,
				loadUserInfo: false,
				revokeAccessTokenOnSignout: true,
				metadata: {
					issuer: this.env.__SSO_OPENID_ISS__,
					authorization_endpoint: this.env.__SSO_BASE_URI__ + this.env.__SSO_AUTHORIZE_URI__,
					token_endpoint: this.env.__SSO_BASE_URI__ + this.env.__SSO_TOKEN_URI__,
					userinfo_endpoint: this.env.__SSO_BASE_URI__ + this.env.__SSO_PROFILE_URI__
				}
			};
			self.userManager = new Oidc.UserManager(ssoConfig);
		},

		login(){
			app.ViewManager.reset();
			app.ViewManager.add('login', new LoginView());
		},

		ssoLogin(){
			const someState = {message: 'some data'};
			this.userManager.signinRedirect({state: someState, useReplaceToNavigate: true})
				.catch((err) => {
					app.error(err);
				});
		},

		ssoCallback(){
			const self = this;
			app.ViewManager.reset();

			// Complete SSO login after redirect
			this.userManager.signinRedirectCallback().then((e) => {
			// Clear the OAuth response from GET params in navigation bar
				self.navigate("", { trigger: false, replace: true });
				$.ajax({
					url: window.g.barebones + "V1/login/sso/" + this.env.__SSO_CLIENT_ID__,
					method: "POST",
					data: {
						access_token: e.access_token
					},

					success(resp) {
						if (resp.user){
							resp.user.ahis = resp.ahis;
							resp = resp.user;
						}
						app.SessionModel = new SessionModel(resp);
						// SSO login succeeded
						app.ViewManager.reset();
						app.ViewManager.add('waiting', new WaitView());
					},

					error(resp) {
					// SSO login failed; return to login
						app.error("Login failed. Please try again");
						self.navigate("", { trigger: true, replace: true });
					}
				});
			}).catch((err) => {
				app.error(err);
			});
		},

		modal(accountId, resourceId) {
			let cus_id, cli_id, pat_id,
				token = location.search.substring(1).split('=')[1];

			if (token && accountId && resourceId){
				app.SessionModel = new SessionModel();
				app.SessionModel.set("ahis", token);
				app.SessionModel.set("usr_fname", "KIOSK");
				app.SessionModel.set("usr_lname", "USER");

				cus_id = accountId.split('-')[0];
				cli_id = accountId.split('-')[1];
				pat_id = accountId.split('-')[2];

				app.ViewManager.reset();
				app.ViewManager.add('modal', new ModalView({
					phs_cus_id: cus_id,
					phs_cli_id: cli_id,
					phs_pat_id: pat_id,
					phs_id: resourceId
				}));
			} else {
				this.login();
			}
		}
	});

	return Router;
});
