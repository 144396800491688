define(['backbone'], function(Backbone) {
	var Model = Backbone.Model.extend({
		sync(method, model, options) {
			if (!options.url) {
				options.url = _.result(model, 'url');
			}

			options.url = model.type === "barebones" ? window.g.barebones + options.url : options.url;
			options.dataType = "json";
			options.origSuccess = options.success;
			options.success = function(response, textStatus, jqXHR) {
				if (response.success == "0") {
					options.error(response, textStatus, jqXHR);
				} else {
					options.origSuccess(response, textStatus, jqXHR);
				}
			};

			if (method === "read") {
				options.xhr = $.ajax(options);
				return options.xhr;
			}

			options.data = options.data || {};

			if (method === "delete") {
				options.type = "delete";
				options.data = JSON.stringify(options.data);
				options.xhr = $.ajax(options);
				return options.xhr;
			}

			options.data.data = model.toJSON();

			delete options.data.data.cid;

			if (method === "create") {
				options.type = "post";
			}

			if (method === "update") {
				options.type = "put";
				options.data = JSON.stringify(options.data);
			}

			options.xhr = $.ajax(options);
			return options.xhr;
		}
	});

	return Model;
});
