define(['app', 'jsignature'], function(app) {
	var SignatureView = app.View.extend({
		el: '#signature',
		template: 'signature',

		events: {
			"click #clear_signature": "onClear",
			"click #cancel_signature": "onCancel",
			"click #save_signature": "onSave"
		},

		render() {
			SignatureView.__super__.render.apply(this, arguments);
			return this;
		},

		postrender() {
			var $signatureArea = this.$el.find("#signature_area");

			$signatureArea.jSignature({
				height: '130px'
			});
			$signatureArea.jSignature('reset');
		},

		onCancel() {
			this.trigger("cancelled");
			this.destroy();
		},

		onClear() {
			var $signatureArea = this.$el.find("#signature_area");
			$signatureArea.jSignature('reset');
		},

		onSave() {
			var $signatureArea = this.$el.find("#signature_area"),
				$parent = (this.options.button.prop("tagName") === "IMG") ? this.options.button : this.options.button.parent(),
				width = $parent.width(),
				height = $parent.height();

			$parent.replaceWith('<img class="signature-signed" src="data:' + $signatureArea.jSignature('getData', 'svgbase64').join(',') + '" width="' + width + '" height="' + height + '" />');
			this.trigger("cancelled");
			this.destroy();
		}
	});
	return SignatureView;
});
