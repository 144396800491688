define(['require', 'app', 'models/SessionModel', 'views/ChallengeView', 'views/WaitView'], function(require, app, SessionModel, ChallengeView, WaitView) {
	var LoginView = app.View.extend({
		el: 'main',
		template: 'login',

		events: {
			"submit #login-form": "onLogin"
		},

		initialize() {
			this.render();
		},

		postrender() {
			import('../vendor/apersona.mjs').then(() => {
				//Set apersona authParam (normally set in window.onload)
				setClientInfo();
			});
		},

		onLogin(e) {
			var self = this,
				email,
				password,
				authParam,
				$btn;

			e.preventDefault();

			email = self.$el.find('#email').val();
			password = self.$el.find('#password').val();
			// needed for testing until the handout signer can support an SSO approach
			authParam = self.$el.find('#authParam').val();
			$btn = self.$el.find('#login_btn');

			$btn.prop('disabled', true);
			$btn.text('Validating...');

			app.SessionModel = new SessionModel();
			app.SessionModel.fetch({
				data: {
					email,
					password,
					// needed for testing until the handout signer can support an SSO approach
					authParam
				},

				success(args, resp) {
					app.ViewManager.reset();
					if (resp.challenged){
						app.ViewManager.add('challenge', new ChallengeView({
							ahis: resp.ahis,
							onCancelled(error) {
								if (error === "force_sso") {
									app.Router.navigate("sso", { trigger: true, replace: true });
									return;
								}
								app.ViewManager.reset();
								app.ViewManager.add('login', new LoginView());
							},
							onSuccess(data) {
								if (data.user){
									data.user.ahis = data.ahis;
									data = data.user;
								}
								app.SessionModel = new SessionModel(data);
								app.ViewManager.reset();
								app.ViewManager.add('waiting', new WaitView(data));
							}
						}));
					} else {
						app.ViewManager.add('waiting', new WaitView(resp));
					}
				},

				error(args, resp) {
					var displayError, codesToMessage = {
						fields_missing: "Please enter your e-mail and your password.",
						invalid_login: "Your e-mail and/or password was entered incorrectly.",
						blocked: "You are currently blocked due to too many login requests.",
						account_locked: "Your account is currently locked. Please contact support.",
						account_disabled: "Your account has been disabled.",
						// "password_expired" redirect is not supported at this time
						password_expired: "Your password must be updated to comply with new security standards.",
						force_sso: "You wil be redirected to the new SSO login in 3 seconds."
					};

					displayError = codesToMessage[resp.error] ? codesToMessage[resp.error] : resp.error;

					app.error(displayError);
					$btn.prop('disabled', false);
					$btn.text('Sign In');

					// redirect to "sso" route defined in router.js
					if (resp.error === "force_sso") {
						window.setTimeout(function(){
							app.Router.navigate("sso", { trigger: true, replace: true });
						}, 3000);
						return;
					}
				}
			});
		}
	});
	return LoginView;
});
