define(['require', 'app'],
(require, app) => {
	const ChallengeView = app.View.extend({
		el: 'main',
		template: 'challenge',

		events: {
			"submit #challenge-form": "onVerify",
			"click #resend_code_btn": "onResendCode"
		},

		initialize(options) {
			this.options = options;
			this.render();
		},

		postrender() {
			import('../vendor/apersona.mjs').then(() => {
				//Set apersona authParam (normally set in window.onload)
				setClientInfo();
			});
		},

		onVerify(e) {
			var self = this, code, authParam, $btn, oldText;
			e.preventDefault();

			code = self.$el.find('#verification_code').val();
			// needed for testing until the handout signer can support an SSO approach
			authParam = self.$el.find('#authParam').val();
			$btn = self.$el.find('#verify_btn');
			oldText = $btn.text();

			$btn.prop('disabled', true);
			$btn.text('Validating...');

			$.ajax({
				type: "POST",
				url: window.g.barebones + "V1/login/challenge",
				dataType: "json",
				data: {
					ahis: self.options.ahis,
					authParam,
					code
				},

				success(resp) {
					if (resp.success != "1") {
						var translated = self.translateMessage(resp.error || resp.msg);
						self.handleServerError(translated);

						$btn.prop('disabled', false);
						$btn.text(oldText);
						return;
					}

					//Verify Challenge passed
					$.ajax({
						url: window.g.barebones + "V1/login/validate_challenge_passed",
						dataType: "json",
						data: {
							ahis: self.options.ahis,
							client: 0
						},
						success(data) {
							$btn.prop('disabled', false);
							$btn.text(oldText);

							if (data.success != "1") {
								translated = self.translateMessage(data.error || data.msg);
								self.handleServerError(translated);
								return;
							}

							// Verification successful
							if (self.options.onSuccess) {
								self.options.onSuccess(data);
							}
						},

						error(){
							app.error('Invalid verification code. Please try again');
							$btn.prop('disabled', false);
						}
					});
				},

				error() {
					app.error('Invalid verification code. Please try again');
					$btn.prop('disabled', false);
					$btn.text(oldText);
				}
			});
		},

		onResendCode(e){
			var self = this,
				authParam = self.$el.find('#authParam').val(),
				$btn = self.$el.find('#resend_code_btn'),
				oldText = $btn.text();

			e.preventDefault();

			$btn.prop('disabled', true);
			$btn.text("Please wait...");

			$.ajax({
				url: window.g.barebones + "V1/login/challenge/resend",
				method: "POST",
				data: {
					ahis: self.options.ahis,
					authParam
				},
				success(resp) {
					$btn.prop('disabled', false);
					$btn.text(oldText);

					if (resp.success != "1") {
						var translated = self.translateMessage(resp.error || resp.msg);
						self.handleServerError(translated);
						return;
					}

					app.success("The verification code has been resent to your e-mail. " +
						"If you still do not receive your verification code within 5 minutes, please contact Support.");
				},
				error(){
					app.error('Unable to resend verification code.');
					$btn.prop('disabled', false);
					$btn.text(oldText);
				}
			});
		},

		handleServerError(translated){
			var self = this;
			app.error(translated.message);
			if (!translated.stay){
				window.setTimeout(function(){
					if (self.options.onCancelled) {
						self.options.onCancelled(translated.raw);
					}
				}, 10000);
			}
		},

		translateMessage(message){
			var translated = {
				raw: message
			};

			if (message === "Invalid Session"){
				translated.message = "Your login has expired.";
			} else if (message === "Invalid OTP"){
				translated.message = "Invalid verification code.";
				translated.stay = true;
			} else if (message === "Please enter a verification code."){
				translated.message = "Please enter a verification code.";
				translated.stay = true;
			} else if (message === "Exceeded attempts for OTP"){
				translated.message = "You have failed to verify your login too many times.";
			} else if (message === "OTP no longer valid, please login again" || message === "You do not have a challenge to verify."){
				translated.message = "This verification has already been completed in another tab or browser window.";
			} else if (message === "OTP time out"){
				translated.message = "Your verification code has expired.";
			} else {
				translated.message = "There was an error verifying your login.";
			}
			if (!translated.stay){
				if (message === "force_sso") {
					translated.message += " To continue, please login again. (You will be redirected to the new SSO login screen in 10 seconds).";
				} else {
					translated.message += " To continue, please login again. (You will be redirected to the login screen in 10 seconds).";
				}
			}
			return translated;
		}
	});
	return ChallengeView;
});
