define(['app', 'models/HandoutModel', 'views/HandoutView'], function(app, HandoutModel, HandoutView) {
	var ModalView = app.View.extend({
		el: 'main',
		template: 'modal',

		ahisAttribute: 'ahis',
		cus_idAttribute: 'phs_cus_id',
		cli_idAttribute: 'phs_cli_id',
		pat_idAttribute: 'phs_pat_id',
		phs_idAttribute: 'phs_id',

		initialize(options) {
			this.options = options;
			this.render();
		},

		postrender() {
			var self = this;

			self.handout = new HandoutModel(self.options);

			self.handout.fetch({
				data: {
					ahis: app.SessionModel.get("ahis")
				},
				success(args, resp) {
					self.onHandoutReceived(resp);
				},

				error() {
					app.error('Unable to load handout');
				}
			});
		},

		onHandoutReceived(resp) {
			var self = this, handoutView;
			// handout received

			self.phs_id = resp.phs_id;

			self.$el.find('#waiting').hide();

			handoutView = new HandoutView({
				hideControls: true
			});

			// confirmation sent

			// render handout view
			handoutView.render({
				handout: resp.data
			});

			self.listenToOnce(handoutView, 'cancelled', function() {
				// webkit is defined by the browser; may cause compatibility issues outside of ipad webkit usage
				if (window.webkit){
					// send 'cancelled' message to webkit/native application interface
					window.webkit.messageHandlers.onHandoutCancelled.postMessage({});
				}
			});

			self.listenToOnce(handoutView, 'send', function(hand) {
				if (hand.phs_content){
					self.handout.set('phs_content', hand.phs_content);
					self.handout.save(undefined, {
						contentType: "application/json",
						data: {
							ahis: app.SessionModel.get('ahis')
						},
						success() {
							// sent handout to ehr

							// webkit is defined by the browser; may cause compatibility issues outside of ipad webkit usage
							if (window.webkit){
								// send 'submitted' message to webkit/native application interface
								window.webkit.messageHandlers.onHandoutSubmitted.postMessage({});
							} else {
								// fallback to success message; this window should normally be closed automatically by the webkit handler
								app.success("Handout was successfully submitted. You may now close this window.");
							}

							handoutView.destroy();
							self.resetView();
						},
						error() {
							app.error("There was a problem saving the handout.");
						}
					});
				}

			});
		},

		resetView() {
			this.$el.find('#waiting').show();
			this.handout = null;
		}
	});
	return ModalView;
});
